import http from "../http-common";
import authHeader from './auth-header';

class CommunicationService {
  endpoint = "/communication/"
  // Retrieve all
  get() {
    return http.get(this.endpoint, { headers: authHeader() });
  }

  // Retrieve one id
  getOne(id) {
    return http.get(this.endpoint+id, { headers: authHeader() });
  }

  // Retrieve from one user
  getUser(userId) {
    return http.get(this.endpoint, { params : {user: userId}, headers: authHeader() });
  }

  // Retrieve from one user
  setReadedUser(userId) {
    return http.get(this.endpoint, { params : {user: userId}}, { headers: authHeader() });
  }
  
  // Update a vehicle corresponding to an id
  update(id, data) {
    return http.put(this.endpoint+id, data, { headers: authHeader() });
  }
  
  // Create a vehicle
  create(data) {
    return http.post(this.endpoint, data, { headers: authHeader() });
  }
  
}

export default new CommunicationService();
