import http from "../http-common";
import authHeader from './auth-header';
import store from '../store'
class AuthService {
  // User login
  register(user) {
    console.log(user)
    return http.post(`/register/`, user).then(response => {
      console.log("register response", response)
      if (response.data.accessToken) {
        store.state.auth = response.data
        console.log("auth.service# store",store.state.auth)
      }
      return response.data;
    }).catch(error => {
      console.log(error)
      return { error: error.message };
    });
  }
  // User login
  login(user) {
    return http.post(`/login/`, { email: user.email, password: user.password})
      .then(response => {
        console.log("login response", response)
        if (response.status !=200) {
          throw new Error(response)
        }
        if (response.data.accessToken) {
          store.state.auth = response.data
          console.log("auth.service# store",store.state.auth)
        }
        return response.data;
      }).catch(error => {
        console.log("login response error ", error.response)
        throw new Error(error.response.data.message)
      });
  }

  // User logout
  logout() {
    localStorage.removeItem('user');
    store.state.auth = null
  }
  
  async verify(token) {
    let res = await http.post(`/verification/`, { token: token})
    return res
  }

  async getResetPassword(email) {
    let res = await http.get(`/reset-password/`, { params: {email: email}})
    return res
  }

  async resetPassword(token, password) {
    let res = await http.post(`/reset-password/`, { headers: authHeader(), token: token, password: password})
    return res
  }
}
export default new AuthService();