<template>
  <div class="topbar">
    <div v-if="loggedUser">
      <div>
        <va-button size="large" preset="secondary" @click="$router.push('/me')" class="pr-1">{{ loggedUser.stageName }}<i class="fa fa-user"></i></va-button>
        <va-button-dropdown
          size="large"
          preset="secondary"
          class="pr-1"
          icon="message"
        >
          <div class="header-notification">
            <span>Notifications</span>
            <div v-for="(communication, idx) in communications" :key='idx' class="mb-1">
              <va-badge v-if="!communication.read" overlap dot/>
              <h2>{{ communication.subject }}</h2>
              <span>{{ communication.description }}</span>
              <va-divider/>
            </div>

            <va-button size="large" @click="toogleNotification" class="close-notification"><i class="fa fa-window-close"></i></va-button>
          </div>
        </va-button-dropdown>
        <va-badge v-if="unreaded" left dot>
        </va-badge>
        <va-button size="large" preset="secondary" @click="logout" class="pr-1"><i class="fa-solid fa-right-from-bracket"></i></va-button>
      </div>  
    </div>
    <div v-else>
      <div>
        <va-button size="large" preset="secondary" @click="$router.push('/login')" class="pr-1"><i class="fa fa-right-to-bracket"></i></va-button>
        <va-button size="large" preset="secondary" @click="$router.push('/register')" class="pr-1"><i class="fa fa-user-plus"></i></va-button>
      </div>
    </div>
  </div>
  
</template>
<script>
import CommunicationService from '../../services/communication.service.js'
export default {
  name: 'TopBar',
  data() {
    return {
      communications: null,
      isVisible: false
    }
  },
  props: {
    showingNotification: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '290px'
    },
  },
  computed: {
    unreaded() {
      let count = 0;
      if (this.communications) this.communications.forEach((element) => { if (!element.read) count++})
      return count;
    },
    loggedUser() {
      return this.$store.state.auth?.user;
    },
    notificationHeight() {
      return this.isVisible ? '100%' : '0%'
    }
  },
  methods: {
    // Manages the user's login
    logout() {
        this.$store.dispatch("auth/logout").then(
            () => {
              console.log("test")
              return this.$router.push({ path: '/' });
            },
            (error) => {
              console.log("test2")
              this.loading = false;
              this.message = (error.response && error.response.data &&error.response.data.message) || error.message || error.toString();
            }
        );
    },
    toogleNotification() {
      this.isVisible = !this.isVisible;
      console.log("toogleNotification");
    },
    clearComm() {
      CommunicationService.setReadedUser(this.loggedUser?.email).then(
        () => {
          this.communications.forEach(element => element.read = true);
          console.log("ok")
        },
        (error) => {
          this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
      );
    }
  },
  async created() {
    if (this.loggedUser) {
      CommunicationService.getUser(this.loggedUser?.email).then(
        (response) => {
          this.communications = response.data;
        },
        (error) => {
          this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
      );
    }
  }
}
</script>
<style scoped>
    .topbar{
    width: 100%;
    height: 60px;
    z-index: 999;
    background-color: #ffffff;
    top: 0;
    right: 0;
    position: fixed;
    display: flex; 
    justify-content: flex-end;
    vertical-align: middle;
    box-shadow: 0.1px 0.1px 15px 0.1px #273c75;
    }
    .btn{
      margin:2px;
    }
    .header-notification {
      height: 100%;
    }
    .close-notification {
      margin-left: auto
    }
    .notificationCenter {
      position: fixed;
      background-color: #dddddd;
      width: 290px;
      height: 100%;
      right: 0;
      transition: 0.3s max-height ease;
    }
    .dotted:after {
      content: "";
    position:absolute;
    z-index:100;
    height: 10px;
  width: 10px;
  background-color: #b83b3b;
  border-radius: 50%;
  display: inline-block;
}
</style>