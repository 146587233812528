<template>
  <div class="row items-center">
    <div class="flex flex-col xs12">
      <div class="item">
        <img src="@/assets/logo.png" width="250"/>
      </div>
      <div class="item">
        Artiist. ? Qu'est ce que c'est ?
      </div>
      <va-carousel
        :items="items"
        stateful
        autoscroll
        :autoscroll-interval="2000"
        :arrows="false"
        :indicators="false"
        :infinite="false"
      >
      <template #default="{ item  }">
        {{ item  }}
      </template>
      </va-carousel>
      <div class="item">
        <router-link to="/login">Se connecter</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicHome',
  props: {
    msg: String
  },
  data() {
    return {
      items: ["Imaginez", "Ne plus avoir", "à payer de commissions", "sur vos cachets de prestation", "Bienvenue chez Artiist. !"],
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
<style lang="scss">
.va-carousel__slide {
  font-size: 48px;
}
.item {
  text-align: center;
  padding:20px;
}
</style>
