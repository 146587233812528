
import { createWebHistory, createRouter } from "vue-router";
import PublicHome from "./components/PublicHome.vue";
import store from './store'

// Lazy-loaded part
const Login = () => import("./components/Login.vue");
const LostPassword = () => import("./components/LostPassword.vue");
const ResetPassword = () => import("./components/ResetPassword.vue");
const Register = () => import("./components/Register.vue");
const Home = () => import("./components/Home.vue")
const User = () => import("./components/User.vue")
const Profile = () => import("./components/Profile.vue")
const EventValidation = () => import("./components/EventValidation.vue")
const EventFeedback = () => import("./components/EventFeedback.vue")
const EventList = () => import("./components/EventList.vue")
const Event = () => import("./components/Event.vue")
const EventMS = () => import("./components/EventMultiStep.vue")
const ContractValidation = () => import("./components/ContractValidation.vue")
const DeclarationList = () => import("./components/DeclarationList.vue")
const Declaration = () => import("./components/Declaration.vue")
const InvoiceList = () => import("./components/InvoiceList.vue")
const Invoice = () => import("./components/Invoice.vue")
const ClientList = () => import("./components/ClientList.vue")
const Client = () => import("./components/Client.vue")
const Verification = () => import("./components/Verification.vue")
const Settings = () => import("./components/Settings.vue")
//const NotFoundComponent = () => import("./components/Home.vue")

// All routes in the application
const routes = [
  {path: "/",name: "publicHome",component: PublicHome,},
  {path: "/home",name: "home",component: Home,},
  {path: "/login",name: "login",component: Login,},
  {path: "/register",name: "register",component: Register,},
  {path: "/lost-password",name: "lostPassword",component: LostPassword,},
  {path: "/reset-password/:token",name: "reset-password",component: ResetPassword,},
  {path: "/me",name: "profile",component: Profile,},
  {path: "/user",name: "user",component: User,},
  {path: "/validation/event/:id/:control",name: "event-validation", component: EventValidation,},
  {path: "/feedback/event/:id/:control",name: "event-feedback", component: EventFeedback,},
  {path: "/event",name: "event-list", component: EventList,},
  {path: "/event/add",name: "event-add",component: EventMS,},
  {path: "/event/:id",name: "event",component: Event,},
  {path: "/signature/contract/:id/:control",name: "contract-validation", component: ContractValidation,},
  {path: "/client",name: "client-list",component: ClientList,},
  {path: "/client/:id",name: "client",component: Client,},
  {path: "/verification/:token",name: "verification",component: Verification,},
  {path: "/declaration",name: "declaration-list",component: DeclarationList,},
  {path: "/declaration/:id",name: "declaration",component: Declaration,},
  {path: "/invoice",name: "invoice-list",component: InvoiceList,},
  {path: "/invoice/:id",name: "invoice",component: Invoice,},
  {path: "/setting",name: "settings",component: Settings,},
  {path: "/:pathMatch(.*)*",name: "publicHome",component: PublicHome}
];

const router = createRouter({mode: 'history', history: createWebHistory(),routes,});
export default router;

// Management of public and private pages
router.beforeEach((to, from, next) => {
  const publicPages = [/\/login/, /\/register/, /validation\/event/, /feedback\/event/,/lost-password/, /reset-password/];
  const adminPages = ['/setting', '/invoice', '/declaration'];
  const authRequired = !publicPages.some(rx => rx.test(to.path));
  const adminRequired = !adminPages.includes(to.path);
  const loggedIn = store.state.auth?.user;
  const role = store.state.auth?.user?.role || "visitor";
  console.log("router.beforeEach"+ to.path+" ",authRequired," ",store)
  // Trying to access a restricted page and not logged in
  if (authRequired && !loggedIn) {
    console.log("Missing auth ... redirect to login...")
    sessionStorage.setItem('redirectPath', to.path)
    next({ path:'/login' }); // Redirect to login page
  } 
  // Trying to access a restricted page and logged in
  else {
    if (adminRequired && !role=='admin') {
      console.log("Not an admin... redirect to hoem...")
      next('/'); // Redirect to home page
    } else {
        next(); // Redirect to the requested page
    }
  }
});
