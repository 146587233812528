<template>
  <TopBar />
  <div class="main-layout">
    <sidebar-menu
      v-if="loggedUser"
      v-model:collapsed="collapsed"
      :menu="menu"
      :theme="selectedTheme"
      :show-one-child="true"
      @update:collapsed="onToggleCollapse"
      @item-click="onItemClick"
    />
    
    <div v-if="isOnMobile && !collapsed" class="sidebar-overlay" @click="collapsed = true" />
    <div id="main" :class="[{'collapsed' : collapsed}, {'onmobile' : isOnMobile}, { 'hidden' : !loggedUser}]">
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { h, markRaw } from 'vue'
import TopBar from './components/topbar/TopBar.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const faIcon = (props) => {
  return {
    element: markRaw({
      render: () => h('div', [
        h(FontAwesomeIcon, { size: 'lg', ...props })
      ])
    })
  }
}

export default {
  name: 'App',
  components: {
    TopBar
  },
  data () {
    return {
      menu: [
        {
          header: 'Artiist.',
          hiddenOnCollapse: true
        },
        {
          href: '/home',
          title: 'Tableau de bord',
          icon: faIcon({ icon: 'fa fa-house-user' }),
          badge: {
              text: 'new',
              class: 'vsm--badge_default'
              // attributes: {}
              // element: 'span'
          }
        },
        {
          title: 'Evénements',
          icon: faIcon({ icon: 'fa fa-calendar' }),
          child: [
            { href: '/event', title: 'Mes événements' },
            { href: '/event/add', title: 'Créer un événement' }
          ]
        },
        {
          title: 'Clients',
          icon: faIcon({ icon: 'fas fa-address-book' }),
          child: [
            { href: '/client', title: 'Mes clients' },
            { href: '/client/add', title: 'Créer un client' }
          ]
        },
        {
          title: 'Mes déclarations',
          icon: faIcon({ icon: 'fa-regular fa-file-lines' }),
          href: '/declaration'
        },
        {
          title: 'Utilisateurs',
          icon: faIcon({ icon: 'fas fa-user' }),
          child: [
            { href: '/user', title: 'Tous les utilisateurs' },
            { href: '/declaration', title: 'Déclarations salariales' }
          ]
        },
        {
          title: 'Facturation',
          icon: faIcon({ icon: 'fas fa-file-invoice-dollar' }),
          child: [
            { href: '/invoice', title: 'Voir les factures' },
            { href: '/invoice/add', title: 'Créer une facture' }
          ]
        },
      ],
      hidden: false,
      collapsed: false,
      themes: [
        {
          name: 'Default theme',
          input: ''
        },
        {
          name: 'White theme',
          input: 'white-theme'
        }
      ],
      selectedTheme: 'white-theme',
      isOnMobile: false
    }
  },
  computed: {
    loggedUser() {
      console.log("loggedUser "+(this.$store.state.auth?.user != null))
      return this.$store.state.auth?.user != null;
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onToggleCollapse (collapsed) {
      console.log('onToggleCollapse')
    },
    onItemClick (event, item) {
      console.log('onItemClick')
      // console.log(event)
      // console.log(item)
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  background-color: #f2f4f7;
  color: #262626;
}

#main {
  padding-left: 290px;
  padding-top: 3em;
  transition: 0.3s ease;
}
#main.hidden {
  padding-left: 0px;
}
#main.collapsed {
  padding-left: 65px;
}
#main.onmobile {
  padding-left: 65px;
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

.demo {
  padding: 50px;
}

.main-layout {
  padding-top: 60px;
}
.container {
  max-width: 900px;
}
</style>