import AuthService from '../services/auth.service';

export const auth = {
  namespaced: true,
  state: JSON.parse(localStorage.getItem('user')),
  actions: {
    // User login
    login({ commit }, user) {
      return AuthService.login(user).then(
        userRes => {
          console.log("auth.login# resp", userRes)
          if (user.keepLoggedIn) {
            console.log("store in localstorage"+ JSON.stringify(userRes))
            localStorage.setItem('user', JSON.stringify(userRes))
          }
          commit('loginSuccess', userRes);
          return Promise.resolve(userRes);
        },
        error => {
          console.log("auth.login# error", error)
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    // User logout
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    }
  },
  mutations: {
    // Login success
    loginSuccess(state, user) {
      state = user;
    },
    // Login failed
    loginFailure(state) {
      // eslint-disable-next-line
      state = null;
    },
    // User logout
    logout(state) {
      // eslint-disable-next-line
      state = null;
      console.log("auth.module.logout", state)
    }
  }
};
