import store from '../store'
export default function authHeader() {
  console.log("authHeader# ",JSON.parse(localStorage.getItem('user')), store.state.auth)
  // Presence of a token
  if (store.state.auth && store.state.auth.accessToken) {
    return { 'x-access-token': store.state.auth.accessToken };
  } 
  // No token present
  else {
    return {};
  }
}
